;(function () {
	'use strict';
	ready(function() {
		// see https://mediatemple.net/blog/tips/using-intersectionobserver-to-check-if-page-scrolled-past-certain-point-2/
	    if (
	    	"IntersectionObserver" in window &&
	    	"IntersectionObserverEntry" in window &&
	    	"intersectionRatio" in window.IntersectionObserverEntry.prototype
		) {
	    	var observer = new IntersectionObserver(function(entries) {
	    		if (entries[0].boundingClientRect.y <= 0) {
	    			document.body.classList.add("scrolled");
	    		} else {
	    			document.body.classList.remove("scrolled");
	    		}
	    	});
	    	observer.observe(document.querySelector("#scrollcheck"));
		}
	});
}());
